import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

/*
 * Navigation Controller
 * */

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.create({
    trigger: "git st",
    start: "50px",
    toggleClass: {targets: "html", className: "has-scrolled"},
});


